//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
// $FlowFixMe.
import { withStyles } from '@material-ui/core/styles';
import Interview from './Interview';
import './interviewer.scss';
// $FlowFixMe.
import moment from 'moment';

const styles = {
  root: {
    backgroundColor: 'red',
  },
  container: { display: 'flex', flexDirection: 'column', margin: '5rem', width: '40rem' },
  resize: {
    fontSize: 15,
  },
  myHelperTextStyle: {
    fontSize: 12,
    color: 'Red',
  },
};
type Props = {
  user: Object,
};
class InterviewerDashboard extends Component<Props> {
  render() {
    const { user } = this.props;

    const interviewer = user.interviewerId;
    return (
      <div className="interviewer">
        <div className="table">
          <h3 className="title">
            Upcoming Candidate Interview Schedule
            <a
              style={{ cursor: 'pointer' }}
              onClick={() =>
                window.open(
                  `https://www.loom.com/share/ca2d7d2aac734935afb779b29942f76d?sid=a6ceb360-ef94-4465-89f1-1619e5bd918f`,
                  '_blank',
                )
              }
            >
              <></>(Training Video)
            </a>
          </h3>
          {interviewer !== undefined && interviewer.assignedCandidateIds.length > 0 ? (
            <div className="thead">
              <div className="columnAction">S. No</div>
              <div className="columnSmall">Candidate Name</div>
              <div className="columnSmall">Cohort</div>
              <div className="columnMedium">Slack Id</div>
              <div className="columnSmall">Strong Student?</div>
              <div className="columnSmall">LinkedIn</div>
              <div className="columnMedium">Date</div>
              <div className="columnSmall">Start Time</div>
              <div className="columnSmall">End Time</div>
              <div className="columnSmall">Add Feedback</div>
            </div>
          ) : (
            <h4>No Interviews Pending</h4>
          )}
          {interviewer !== undefined &&
            interviewer.assignedCandidateIds.map((data, index) => {
              const { allCohortIds } = data.candidateId;
              return (
                <Interview
                  key={data._id}
                  index={index}
                  id={data.candidateId._id}
                  candidateName={
                    data.candidateId.userId.firstName + ' ' + data.candidateId.userId.lastName
                  }
                  isLeadership={false}
                  slackId={data.candidateId.userId.slackId}
                  isQCCleared={data.candidateId.isQCCleared}
                  linkedInUrl={data.candidateId.linkedInUrl}
                  cohort={allCohortIds[allCohortIds.length - 1].name}
                  date={moment(data.timeSlot.startTime)
                    .format('ddd MMM D, YYYY')
                    .toString()}
                  startTime={moment(data.timeSlot.startTime)
                    .format('hh : mm A')
                    .toString()}
                  endTime={moment(data.timeSlot.endTime)
                    .format('hh : mm A')
                    .toString()}
                />
              );
            })}
        </div>
        {interviewer !== undefined && interviewer.assignedCandidateIds.length > 0 ? (
          <>
            <br></br>
            <h4 className="title">The times displayed are as per your system/device timezone</h4>
            <h5>
              Interview Scoring Standards
              <a
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  window.open(
                    `https://docs.google.com/document/d/1X7LW20uNMrOt_aVWizmLX-To9xmBiK6PKwB8JZ4D9aw/edit`,
                    '_blank',
                  )
                }
              >
                <></> Click Here
              </a>
            </h5>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.authState.user,
});

export default withStyles(styles)(connect(mapStateToProps, {})(InterviewerDashboard));
